import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { SpecializationsDesktop } from './SpecializationsDesktop';
import { SpecializationsPhone } from './SpecializationsPhone';
import { SpecializationsTablet } from './SpecializationsTablet';

export const Specializations = () => {
    const theme = useTheme();
    const mediaPhone = useMediaQuery(theme.breakpoints.down('sm'));
    const mediaTabletPortrait = useMediaQuery(theme.breakpoints.down('md'));

    if (mediaPhone) return <SpecializationsPhone />;
    if (mediaTabletPortrait) return <SpecializationsTablet />;

    return <SpecializationsDesktop />;
};
