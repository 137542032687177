import React from 'react';
import { SubPageServiceTheme } from './SubPageServiceTheme';
import { Text } from '../components/Text';
import { SubPageTheme } from '../components/SubPageTheme';
import { Service } from './PageServices';

export const PageServiceCreatingContracts = () => {
    return (
        <SubPageTheme headerBg="/resources/images/TOP_Tworzenie_I_OPINIOWANIE_UMOW.jpg">
            <SubPageServiceTheme header="Tworzenie i opiniowanie umów" activeService={Service.CreatingContracts}>
                <Text>
                    Prawnik dba o przygotowanie umowy, która będzie zrozumiała dla obu stron. Moim zadaniem jest, aby treść całej umowy nie budziła żadnych wątpliwości i nie pozostawiała dodatkowego pola do interpretacji, a przede wszystkim była zgodna z prawem i zasadami współżycia społecznego. Odwzorowuję przy tym intencję stron oraz ich interesy. Przestrzeganie tych wytycznych daje Klientom poczucie, że otrzymują umowę według aktualnego stanu prawnego, która wpływa na bezpieczeństwo ich interesów.
                </Text>
            </SubPageServiceTheme>
        </SubPageTheme>
    );
};
