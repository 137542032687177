import React from 'react';
import styled from 'styled-components';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { Paragraph } from '../../components/Paragraph/Paragraph';
import { MenuButton } from '../../components/MenuButton';
import { Text } from '../../components/Text';
import { Link, useLocation } from 'react-router-dom';
import { SectionHeader } from '../../components/Text';

interface IMenuProps {
    open: boolean;
}

interface INavProps {
    logoVisible: boolean;
    textVisible: boolean;
    activeSlide: number;
}

interface IVisibilityProps {
    visible: boolean;
}

enum Image {
    About,
    Specs,
    Services,
    Contact,
}

const imageMap = {
    [Image.About]: '/resources/images/MENU_GLOWNA_FOTA_o_kancelarii.jpg',
    [Image.Specs]: '/resources/images/MENU_GLOWNA_FOTA_zakres_uslug.jpg',
    [Image.Services]: '/resources/images/MENU_GLOWNA_FOTA_specjalizacje.jpg',
    [Image.Contact]: '/resources/images/MENU_GLOWNA_FOTA_kontakt.jpg',
};

const menuItems = [
    { text: 'O Kancelarii', link: '/o-kancelarii', image: Image.About },
    { text: 'Zakres usług', link: '/zakres-uslug', image: Image.Specs },
    { text: 'Specjalizacje', link: '/specjalizacje', image: Image.Services },
    { text: 'Kontakt', link: '/kontakt', image: Image.Contact },
];

interface ILocation {
    text: string;
    textColor: string;
    hamburgerColor: string;
}

const locations: { [key: string]: ILocation } = {
    '/': {
        text: 'RADCA PRAWNY MICHAŁ OLCZYK',
        textColor: 'secondary',
        hamburgerColor: '#083E71',
    },
    '/o-kancelarii': {
        text: 'RADCA PRAWNY MICHAŁ OLCZYK',
        textColor: 'primary',
        hamburgerColor: '#fff',
    },
    '/zakres-uslug': {
        text: 'ZAKRES USŁUG',
        textColor: 'secondary',
        hamburgerColor: '#083E71',
    },
    '/zakres-uslug/udzielanie-porad-prawnych': {
        text: 'ZAKRES USŁUG',
        textColor: 'primary',
        hamburgerColor: '#fff',
    },
    '/zakres-uslug/sporzadzanie-opinii-prawnych': {
        text: 'ZAKRES USŁUG',
        textColor: 'primary',
        hamburgerColor: '#fff',
    },
    '/zakres-uslug/tworzenie-i-opiniowanie-umow': {
        text: 'ZAKRES USŁUG',
        textColor: 'primary',
        hamburgerColor: '#fff',
    },
    '/zakres-uslug/zastepstwo-procesowe': {
        text: 'ZAKRES USŁUG',
        textColor: 'primary',
        hamburgerColor: '#fff',
    },
    '/specjalizacje': {
        text: 'SPECJALIZACJE',
        textColor: 'primary',
        hamburgerColor: '#fff',
    },
    '/kontakt': {
        text: 'KONTAKT',
        textColor: 'primary',
        hamburgerColor: '#fff',
    },
};

export const Nav = ({ logoVisible, textVisible, activeSlide }: INavProps) => {
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [menuShrinked, setMenuShrinked] = React.useState(false);
    const [image, setImage] = React.useState<Image | null>();

    const theme = useTheme();
    const devicePhone = useMediaQuery(theme.breakpoints.down('sm'));
    const deviceTablet = useMediaQuery(theme.breakpoints.down('md'));

    const location = useLocation();

    React.useEffect(() => {
        window.addEventListener('scroll', listenToScroll);

        return () => {
            window.removeEventListener('scroll', listenToScroll);
        };
    }, []);

    React.useEffect(() => {
        document.body.style.overflow = 'hidden;';
    }, [menuOpen]);

    function listenToScroll() {
        const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;

        if (scrollTop > 30) {
            setMenuShrinked(true);
        } else {
            setMenuShrinked(false);
        }
    }

    function handleResetMenu() {
        window.scroll(0, 0);
        setMenuOpen(false);
        setImage(null);
    }

    function getHeaderText() {
        const loca = locations[location.pathname];

        return <SectionHeader color={loca.textColor}>{loca.text}</SectionHeader>;
    }

    function getHamburgerColor() {
        const color = locations[location.pathname].hamburgerColor;

        if (location.pathname === '/') {
            return activeSlide === 0 ? 'white' : color;
        }

        return color;
    }

    function isNavTextVisible() {
        if (menuOpen) return false;
        if (location.pathname !== '/') return true;

        return textVisible;
    }

    function isNavLogoVisible() {
        const landingPage = location.pathname === '/';
        if (!landingPage) return true;
        if (landingPage && deviceTablet) return true;

        return logoVisible || menuOpen;
    }

    function getLogoSrc() {
        if (menuOpen) return '/resources/logos/100-white.svg';
        if (menuShrinked) return '/resources/logos/100-blue.svg';

        if (location.pathname === '/') {
            if (activeSlide === 0) {
                return '/resources/logos/100-white.svg';
            }

            return '/resources/logos/100-blue.svg'
        }

        if (locations[location.pathname].textColor === 'primary') {
            return '/resources/logos/100-white.svg';
        } else if (locations[location.pathname].textColor === 'secondary') {
            return '/resources/logos/100-blue.svg';
        }

        return '/resources/logos/100-blue.svg';
    }

    return (
        <Container shrinked={menuShrinked} mainPage={location.pathname === '/'}>
            <MenuContainer open={menuOpen}>
                <MenuBgImages>
                    <MenuBgImage active={image === Image.About} bg={imageMap[Image.About]} />
                    <MenuBgImage active={image === Image.Specs} bg={imageMap[Image.Specs]} />
                    <MenuBgImage active={image === Image.Services} bg={imageMap[Image.Services]} />
                    <MenuBgImage active={image === Image.Contact} bg={imageMap[Image.Contact]} />
                </MenuBgImages>

                <Menu container justifyContent="center" alignItems="center" direction="column">
                    <MenuWrapper>
                        {menuItems.map((item: any, key: number) => (
                            <MenuLink
                                key={key.toString()}
                                onMouseEnter={() => setImage(item.image)}
                                onMouseLeave={() => setImage(null)}
                            >
                                <Link to={item.link} onClick={handleResetMenu}>
                                    {item.text}
                                </Link>
                            </MenuLink>
                        ))}
                    </MenuWrapper>
                </Menu>
            </MenuContainer>

            <Navigation container alignItems={menuShrinked ? 'center' : 'flex-start'} justifyContent="space-between">
                <Link to="/" onClick={handleResetMenu}>
                    <Logo visible={isNavLogoVisible()} src={getLogoSrc()} />
                </Link>
                {!devicePhone ? (
                    <NavTextContainer visible={isNavTextVisible()}>
                        <NavText>{getHeaderText()}</NavText>
                    </NavTextContainer>
                ) : null}
                <MenuButton
                    color={getHamburgerColor()}
                    shrinked={menuShrinked}
                    open={menuOpen}
                    onClick={() => setMenuOpen((state) => !state)}
                />
            </Navigation>
        </Container>
    );
};

interface INavTextSpanProps {
    color: string;
}

const Navigation = styled(Grid)`
    position: relative;
`;

const NavTextSpan = styled.span<INavTextSpanProps>`
    color: ${({ color }: INavTextSpanProps) => color};
    transition: all 0.3s ease-in;
`;

interface IContainerProps {
    shrinked: boolean;
    mainPage: boolean;
}

const NavTextContainer = styled.div<{ visible?: boolean }>`
    opacity: ${(props) => (props.visible ? '1' : '0')};
`;

const NavText = styled.div`
    transform: translateX(6px);
    margin-top: -3px;
`;

const Container = styled.div<IContainerProps>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: ${({ shrinked }: IContainerProps) => (shrinked ? '30px 70px' : '60px 70px 0')};
    z-index: 10;

    transition: all 0.3s ease-in;
    background: ${({ shrinked, mainPage }: IContainerProps) => (shrinked ? '#fff' : 'transparent')};

    & ${SectionHeader} {
        color: ${({ shrinked }: IContainerProps) => (shrinked ? '#083E71' : 'auto')};
    }
    
    ${(props) => props.theme.breakpoints.down('md')} {
        padding: ${({ shrinked }: IContainerProps) => (shrinked ? '20px 100px' : '50px 100px 0')};
    }
    
    ${(props) => props.theme.breakpoints.down('sm')} {
        padding: 20px 20px;
    }
`;

const Logo = styled.img<IVisibilityProps>`
    position: relative;
    height: 40px;
    opacity: ${({ visible }: IVisibilityProps) => (visible ? '1' : '0')};
    transition: opacity 0.3s ease-in;
`;

const MenuContainer = styled.div<IMenuProps>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    max-height: -webkit-fill-available;
    opacity: ${({ open }: IMenuProps) => (open ? '1' : '0')};
    visibility: ${({ open }: IMenuProps) => (open ? 'visible' : 'hidden')};
    background: #fff;

    transition: all 0.2s ease-in;
`;

const Menu = styled(Grid)`
    width: 100vw;
    height: 100vh;
    position: relative;
`;

const MenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    & p a {
        transition: all 0.2s ease-in;
        color: #eaf5f8;
    }

    &:hover p a {
        color: #6c7f96;
    }

    &:hover p:hover a {
        color: #eaf5f8;
    }
`;

const MenuLink = styled(Text)`
    cursor: pointer;
    font-size: 43px;
    position: relative;
    transition: all 0.2s ease-in;
    display: block;
    width: 100%;
    text-align: center;
    color: #eaf5f8 !important;

    padding: 30px 0;

    &:hover {
        background-color: rgba(8, 62, 113, 0.2);
    }

    ${(props) => props.theme.breakpoints.down('md')} {
        padding: 40px 0;

        & a {
            font-size: 43px !important;
        }
    }

    ${(props) => props.theme.breakpoints.down('sm')} {
        padding: 25px 0;

        & a {
            font-size: 25px !important;
        }
    }
`;

const MenuBgImages = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    // max-height: -webkit-fill-available;
    overflow: hidden;
    // opacity: .2;
    background: #6c7f96;
`;

interface IMenuBgImageProps {
    bg: string;
    active: boolean;
}

const MenuBgImage = styled.div<IMenuBgImageProps>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    // max-height: -webkit-fill-available;
    background-image: url("${({ bg }: IMenuBgImageProps) => bg}");
    background-position: top center;
    background-size: cover;
    background-repeat- no-repeat;
    opacity: ${({ active }: IMenuBgImageProps) => (active ? '1' : '0')};
    transition: all .3s ease-in;
`;
