import React from 'react';
import {Grid, useMediaQuery, useTheme} from '@mui/material';
import styled from 'styled-components';
import {Paragraph} from '../components/Paragraph/Paragraph';
import {CopyRight} from '../components/Text';
import {ContactGoogleMap} from "./ContactGoogleMap";

interface IContactContentProps {
    headerVisible?: boolean;
    page?: 'main' | 'contact'
}

export const Contact = ({headerVisible = true, page = 'contact'}: IContactContentProps) => {
    const theme = useTheme();
    const deviceTabletAndPhone = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            <Grid container justifyContent={'center'}>
                <Grid item md={6}>
                    <Container container>
                        {headerVisible ? (
                            <Header item xs={12} md={12} textAlign="center">
                                <Paragraph size="l" bold margin="s">
                                    Masz wątpliwość, czy Twoja sprawa mieści się w moim katalogu?
                                </Paragraph>
                                <Paragraph size="m">Skontaktuj się ze mną!</Paragraph>
                            </Header>
                        ) : null}

                    </Container>
                </Grid>
            </Grid>
            <Grid container justifyContent={'center'}>
                <Grid item md={5}>
                    <ContactWrapper container direction={'row'}>
                        <Grid item md={6}>
                            <ContactTexts className="landing-page-contact__address">
                                <ContactText bold image={'/resources/icons/icon-14.svg'}>
                                    Adres:
                                </ContactText>
                                <ContactText>Kancelaria Radcy Prawnego</ContactText>
                                <ContactText>Michał Olczyk</ContactText>
                                <ContactText>ul. Brzezińska 14</ContactText>
                                <ContactText>95-040 Koluszki</ContactText>
                            </ContactTexts>
                        </Grid>
                        <Grid item md={6}>
                            <ContactTexts>
                                <ContactText bold>Kontakt:</ContactText>
                                <ContactText image={'/resources/icons/icon-12.svg'}>
                                    <a href="tel:+48661533865">661 533 865</a>
                                </ContactText>
                                <ContactText image={'/resources/icons/icon-13.svg'}>
                                    <a href="mailto:kontakt@kancelariaolczyk.pl">kontakt@kancelariaolczyk.pl</a>
                                </ContactText>
                            </ContactTexts>
                        </Grid>
                    </ContactWrapper>
                </Grid>
            </Grid>
            <Grid item md={12}>
                <div style={{width: '100vw', height: page === 'contact' ? '500px' : ''}}>
                    <ContactGoogleMap disableDefaultUI={deviceTabletAndPhone} />
                </div>
            </Grid>
        </>
    );
};

function ContactText(props: any) {
    return (
        <Grid container alignItems="center">
            <ContactTextImg src={props.image ? props.image : ' '}/>
            <CopyRight bold={props.bold}>{props.children}</CopyRight>
        </Grid>
    );
}

const Container = styled(Grid)`
    padding-top: 20px;

    ${(props) => props.theme.breakpoints.between('md', 'lg')} {
        padding-top: 3vh;
    }

    ${(props) => props.theme.breakpoints.down('md')} {
        padding: 20px 20px 0;
    }
`;

const ContactTexts = styled.div`
    padding-left: 20px;
    padding-top: 10px;

    ${(props) => props.theme.breakpoints.down('md')} {
        &:first-child {
            padding-right: 20px;
        }

        &:last-child {
            margin-top: -5px;
            padding-right: 20px;
        }
    }

    ${(props) => props.theme.breakpoints.down('sm')} {
        display: block;
        width: 100%;
        padding: 20px 20px;
    }
`;

const ContactTextImg = styled.img`
    width: 25px;
    margin-right: 5px;
`;

const Header = styled(Grid)`
    padding-bottom: 50px;
`;

const ContactWrapper = styled(Grid)`
    margin-bottom: 40px;

    ${(props) => props.theme.breakpoints.down('md')} {
        justify-content: center;
        // align-items: center;
    }
`;

