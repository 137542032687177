import React from 'react';
import { Mousewheel, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import logo from '../../resources/icons/icon-15.svg';
import { Grid } from '@mui/material';
import { SlideServices } from './slides/SlideServices';
import { SlideSpecializations } from './slides/SlideSpecializations';
import { SlideContact } from './slides/SlideContact';
import { Swiper as SwiperClass } from 'swiper/types';
import styled from 'styled-components';
import { SlideAbout } from './slides/SlideAbout';

interface ILandingPageProps {
    activeSlide: number;

    onSlideChange(state: number): void;
}

export const LandingPageDesktop = (props: ILandingPageProps) => {
    function handleOnChangeSlide(swiper: SwiperClass) {
        props.onSlideChange(swiper.activeIndex);
    }

    function renderBullet(index: number, className: any) {
        return `    
            <div class="${className} swiper-bullet-custom"></div>
        `;
    }

    return (
        <Swiper
            mousewheel={true}
            speed={800}
            pagination={{
                clickable: true,
                renderBullet,
            }}
            className="swiper-custom"
            direction={'vertical'}
            slidesPerView={1}
            modules={[Mousewheel, Pagination,]}
            onSlideChange={handleOnChangeSlide}
        >
            <SwiperSlide className="landing-page-hello">
                <div className="landing-page-hello__logo">
                    <img src={logo} />
                </div>
                <div className="background" />
                <NextSlideWrapper onClick={() => props.onSlideChange(2)}>
                    <NextSlideText>Przewiń w dół</NextSlideText>
                    <NextSlideTriangle />
                </NextSlideWrapper>
            </SwiperSlide>
            <SwiperSlide>
                <SlideAbout />
            </SwiperSlide>
            <SwiperSlide>
                <SlideServices />
            </SwiperSlide>
            <SwiperSlide>
                <SlideSpecializations />
            </SwiperSlide>
            <SwiperSlide>
                <SlideContact />
            </SwiperSlide>
        </Swiper>
    );
};

export const KnowMore = styled(Grid)`
    padding-top: 40px;
`;

export const NextSlideWrapper = styled.div`
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%) rotate(0deg);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
`

export const NextSlideText = styled.div`
    font-family: Hind,serif;
    font-size: 10px;
    font-weight: 300;
    // margin-bottom: 2px;
    text-transform: uppercase;
    letter-spacing: 3px;
    text-decoration: underline;
    text-underline-offset: 5px;
    
`

export const NextSlideTriangle = styled.div`
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 12px 6px 0 6px;
    border-color: #fff transparent transparent transparent;
    
    z-index: 100000;
    
    -webkit-animation: scale-down-bottom 1s ease-in-out infinite alternate both;
    animation: scale-down-bottom 1s ease-in-out infinite alternate both;
`