import React from 'react';
import { Grid } from '@mui/material';
import { SubPageTheme } from '../components/SubPageTheme';
import styled from 'styled-components';
import { Specializations } from './Specializations/Specializations';
import {SectionHeaderMobile} from "../components/Text";

export const PageSpecializations = () => {
    return (
        <SubPageTheme headerBg="/resources/images/TOP_specjalizacje.jpg">
            <Container container justifyContent="center">
                <Specializations />
            </Container>
        </SubPageTheme>
    );
};

const Container = styled(Grid)`
    margin-top: -80px;
    margin-bottom: 180px;
    padding: 0 20px;
    
    ${(props) => props.theme.breakpoints.up('xl')} {
        margin-bottom: 0;
    }
    
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        & ${SectionHeaderMobile} {
            display: none
        }
    }

    ${(props) => props.theme.breakpoints.down('md')} {
        margin-top: 0;
        margin-bottom: 0;
        padding: 0 100px;
    }

    ${(props) => props.theme.breakpoints.down('sm')} {
        padding-left: 20px;
        padding-right: 20px;
    }
`;
